import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import ClassNames from "classnames"
import headerStyles from "./header.module.css"
import MaterialIcon from "./materialIcon"

const Header = props => {
  const [expanded, setExpanded] = useState(false)
  const [closed, setClosed] = useState(true)
  const onMenuButtonClick = e => {
    setClosed(false)
    // 非同期に実行しないとメニュー展開時にアニメーションしない
    setTimeout(() => setExpanded(!expanded))
  }
  const onMenuTransitionEnd = e => {
    if (e.propertyName === "height" && !expanded) {
      setClosed(true)
    }
  }

  return (
    <header className={headerStyles.header}>
      <div className={headerStyles.header_inner}>
        <div className={headerStyles.header_top}>
          <h1 className={headerStyles.header_title}>
            <Link className={headerStyles.header_title_link} to="/">
              <img
                className={headerStyles.header_title_logo}
                alt={props.sitetitle}
                src={`/Logo.svg`}
              />
            </Link>
          </h1>
          <button
            className={headerStyles.header_menubutton}
            type="button"
            onClick={onMenuButtonClick}
            aria-expanded={expanded}
          >
            <MaterialIcon iconName="menu" text="メニュー" />
            {/* <img alt="メニュー" src={`/menu.svg`} /> */}
          </button>
        </div>
        <ul
          className={ClassNames(headerStyles.header_menu, {
            [headerStyles.header_menu_active]: expanded,
            [headerStyles.header_menu_closed]: closed,
          })}
          onTransitionEnd={onMenuTransitionEnd}
        >
          <li className={headerStyles.header_menu_item}>
            <Link
              className={headerStyles.header_menu_item_link}
              to="/"
              activeClassName={headerStyles.header_menu_item_link_active}
            >
              ホーム
            </Link>
          </li>

          <li className={headerStyles.header_menu_item}>
            <Link
              className={headerStyles.header_menu_item_link}
              to="/products"
              getProps={isPartiallyActive}
              {...props}
            >
              製品アクセシビリティ情報
            </Link>
          </li>
          <li className={headerStyles.header_menu_item}>
            <Link
              className={headerStyles.header_menu_item_link}
              to="/training"
              getProps={isPartiallyActive}
              {...props}
            >
              アクセシビリティ啓発活動
            </Link>
          </li>
          <li className={headerStyles.header_menu_item}>
            <a
              className={headerStyles.header_menu_item_link}
              href="https://form.kintoneapp.com/public/form/show/57b7ffcb69273fcfbedcb7862cfe19aaba26c145dae9e8bae5ba702c7d21d0ae"
              target="_blank"
              rel="noopener noreferrer"
            >
              製品改善に協力
              <MaterialIcon iconName="open_in_new" text="外部リンク" />
            </a>
          </li>
        </ul>
      </div>
    </header>
  )
}

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? {
        className: ClassNames(
          headerStyles.header_menu_item_link,
          headerStyles.header_menu_item_link_active
        ),
        "aria-current": "page",
      }
    : {}
}

Header.propTypes = {
  sitetitle: PropTypes.string,
}

Header.defaultProps = {
  sitetitle: ``,
}

export default Header
