import React from "react"
import footerStyle from "./footer.module.css"
import MaterialIcon from "./materialIcon"
import { Link } from "gatsby"
import classNames from "classnames"

export default props => {
  const backLinkDom = (pathName,Text) => {
    return(
      <Link to={"/"+pathName} className={classNames(footerStyle.footer_breadcrumb_link,footerStyle.footer_breadcrumb_link_back)}><span className={footerStyle.footer_breadcrumb_icon}><MaterialIcon iconName="chevron_left" text="" patchClass="margin0" /></span>{Text}</Link> 
    )
  }

  const renderArticles = (parentName) =>{
    switch (parentName[0]) {
      case 'products':
        return backLinkDom(parentName[0],'製品アクセシビリティ情報');
      case 'training':
        return backLinkDom(parentName[0],'アクセシビリティ啓発活動');
      case 'news':
      return backLinkDom(parentName[0],'最新情報');
      default:
        break;
    }
  }


  const backLink = () => {
    if(!props.path){
      return
    }
    let parentName = props.path.split('/')
    const removals =[""]
    parentName = parentName.filter(function(v){ return ! removals.includes(v)})
    switch (parentName.length){
      case 1:
        return backLinkDom("",'ホーム');
      case 2:
        return renderArticles(parentName)
      default:
        break;
    }

  }

  return (
    <footer className={footerStyle.footer}>
      <div className={footerStyle.footer_breadcrumb}>
        <div className={footerStyle.footer_breadcrumb_wrap}>
        {backLink()}
          <a href="#" className={footerStyle.footer_breadcrumb_link}>
            <span className={footerStyle.footer_breadcrumb_icon}>
              <MaterialIcon iconName="expand_less" text="" patchClass="margin0" />
            </span>
            トップへ
          </a>
        </div>
      </div>
  
      <div className={footerStyle.footer_contents}>
        <div className={footerStyle.footer_contents_wrap}>
          <div className={footerStyle.footer_contents_caption}>
            Copyright {new Date().getFullYear()}
            {` `}
            Cybozu,Inc.
          </div>
          <ul className={footerStyle.footer_contents_links}>
            <li className={footerStyle.footer_contents_links_item}>
              <a href="https://cybozu.co.jp/" className={footerStyle.footer_contents_links_item_link}>
                サイボウズ企業情報
                <MaterialIcon iconName="open_in_new" text="外部リンク" />
              </a>
            </li>
            <li className={footerStyle.footer_contents_links_item}>
              <a href="https://twitter.com/cybozu_a11y" className={footerStyle.footer_contents_links_item_link}>
                <span class="icon--info">
                  <img
                    src={`/Twitter_Logo_WhiteOnImage.svg`}
                    alt="twitter icon"
                    className={footerStyle.footer_contents_links_item_icon}
                  />
                </span>
                @cybozu_a11y
                <MaterialIcon iconName="open_in_new" text="外部リンク" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
  
} 