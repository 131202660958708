import classNames from "classnames"
import React from "react"

export default props => {
  return (
    <>
      <span
        class={classNames("material-icons md-1", props.patchClass)}
        aria-hidden="true"
      >
        {props.iconName}
      </span>
      <span class="ariaIconText">{props.text}</span>
    </>
  )
}
